@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@600;900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

.root {
    overflow: hidden;
    .down_arrow{
        position: fixed;
        left: 0;
        right: 0;
        margin: auto;
        width: clamp(50px, 10vw, 80px);
        height: clamp(50px, 10vw, 80px);
        bottom: 5vh;
        z-index: 100;
        mix-blend-mode: overlay;
    }
    .pinContainer {
        height: 100vh;
        width: 100vw;
        overflow: hidden;

        .panel {
            height: 100vh;
            width: 100vw;
            position: absolute;

            display: flex;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;

            .logo {
                width: clamp(80px, 80vw, 400px);
            }
            
            .img {
                width: clamp(100px, 90vw, 500px);
            }

            .icon{
                position: relative;
                svg{
                    position: absolute;
                    top: -20vh;
                    height: 50vh;
                    width: 50vw;
                }
            }

            span {
                position: relative;
                display: block;

                width: 100%;
                height: fit-content;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;

                font-family: 'Noto Sans JP', sans-serif;
                padding: clamp(26px, 5vw, 5vw);

                .en{
                    font-size: clamp(26px, 10vw, 60px);
                    font-weight: 900;
                    
                    -webkit-text-fill-color: rgba(255, 255, 255, 0); /* （順序に関係なく）色を上書きする */
                    -webkit-text-stroke: 1px #fff;

                    margin-bottom: 1em;
                }
                .jp{
                    font-size: clamp(18px, 2vw, 24px);
                    
                    margin-bottom: 1em;
                    color: #000000;
                }
                small{
                    font-weight: 200;
                    font-family: sans-serif;
                }
            }
            .appicon{
                background: #fff;
                border-radius: 1em;
                border: none;
                padding: clamp(1em, 4vw, 40px);
                width: fit-content;
                margin: auto;
                margin-bottom: 3em;
                img{
                    width: clamp(30px, 10vw, 80px);
                    height: clamp(30px, 10vw, 80px);
                }
            }

            &.blue {
                background-color: #fff;
            }

            &.turqoise {
                background-color: #00e1f4;
            }

            &.green {
                background-color: #ffb154;
            }

            &.bordeaux {
                background-color: #ff49eb;
            }
            &.works {
                background-color: #3381ff;
                // グラデーション
                background-image: linear-gradient(0deg, #00b0ee 0%, #004bdc 100%);
                .jp{
                    color: #fff;
                }
            }
            &.contact {
                .en{
                    -webkit-text-stroke: 1px #000;
                }
                background-color: #fff;
                .logo{
                    width: clamp(50px, 50vw, 400px);
                    opacity: 0.2;
                }
            }
        }

    }

}