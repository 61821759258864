@import './lib/PCScrollBar.module.scss';


.root {
    transform: translateZ(0px);
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .box{

        transform: translateZ(0px);
        height: 100%;

        @include _pcscrollbar;
        overflow: hidden !important;
    }
}

@media only screen and (min-width: 500px) {

    .root {
        
        max-width: 500px;
    }
}



@media only screen and (min-width: 500px) {

    .root {
        max-height: 800px;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.18);
        border-radius: 20px;
    }
}

.copylight{
    display: none;
}

@media only screen and (min-height: 930px) and (min-width: 500px) {

    .copylight{

        display: block;
        font-size: 13px;
        color: #999;
        position: fixed;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
    }
}