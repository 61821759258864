body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*{
    box-sizing: border-box;
}

/* 警告の非表示 */
.firebase-emulator-warning
{
    pointer-events: none;
    animation: 1s linear 1s firebase-emulator-warning-anim both;
}
@keyframes firebase-emulator-warning-anim {
    from { opacity: 1; }
    to   { opacity: 0; }
}