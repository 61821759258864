@import './lib/PCScrollBar.module.scss';

.root{

    @include _pcscrollbar;
}



.top_bg1{
    margin-left: -40%;
    transform:scale(2);
    opacity: 0.7;
}

.top_bg2{
    margin-left: 20%;
    transform:scale(1.5);
}

.top_bg3{
    margin-left: -10%;
    transform:scale(2.2);
}

.top_bg4{
    margin-left: 40%;
    transform:scale(1.8);
}
.top_bg5{
    margin-left: 0%;
    transform:scale(2.5);
}

.iframe{
    border: 0;
    border-radius: 10px;
    margin-top: 10px;
    min-height: 30vh;
}

.footer{
    a{
        color: #fff;
        text-decoration: none;
    }
}