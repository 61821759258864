@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@600;900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

.root {


    * {
        font-family: 'Noto Sans JP', sans-serif;
    }

    section {
        padding: 4rem 2rem;
    }
}

.header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f8f9fa;
    position: relative;

    .logo {
        max-width: 500px;
        margin: 0 auto;
        width: 100px;
    }

    .bottom {
        position: absolute;
        bottom: 1em;
        width: 100%;
        font-size: 1.3em;
        font-weight: 700;
        color: #6c757d;
    }
}

.catchphrase {
    margin-top: 2rem;
    font-size: 1.5rem;
}

.vision {
    background-color: white;
    
    .visionContent {
        max-width: 800px;
        margin: 0 auto;
        padding: 0;
        opacity: 0;
        transform: translateX(50px);
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        
        &.visible {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .visionLead {
        font-size: 1.4rem;
        margin-bottom: 3rem;
        color: #333;
    }

    ul {
        list-style: none;
        padding: 0;
        
        li {
            line-height: 1.5;
            padding: 1rem 0;
            margin-bottom: 1rem;
            font-size: 1.1rem;
        }
    }
}

.sectionTitle {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;

    h2 {
        margin: 0;
        white-space: nowrap;
    }

    &::after {
        content: '';
        height: 1px;
        background-color: #000;
        flex-grow: 1;
    }

    &.visible {
        opacity: 1;
        transform: translateX(0);
    }
}

.products {
    background-color: #f8f9fa;
    
    .product {
        margin-top: 2rem;
        opacity: 0;
        transform: translateX(50px);
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        
        &.visible {
            opacity: 1;
            transform: translateX(0);
        }

        a {
            display: inline-block;
            margin-top: 1rem;
            padding: 0.5rem 1rem;
            background-color: #007bff;
            color: white;
            text-decoration: none;
            border-radius: 4px;
        }
    }
}

.news {
    ul {
        list-style: none;
        padding: 0;
        opacity: 0;
        transform: translateX(50px);
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        
        &.visible {
            opacity: 1;
            transform: translateX(0);
        }
        
        li {
            margin-bottom: 1rem;
            
            time {
                color: #6c757d;
            }
        }
    }
    img {
        width: 100%;
    }
}

.footer {
    margin-top: 2rem;
    padding: 2rem;
    padding-bottom: 10rem;
    background-color: #343a40;
    color: white;
    text-align: center;
    .footerContent {
        a{
            color: white;
            text-decoration: none;
            font-size: .8rem;
        }
        margin-bottom: 1rem;
    }
}

.mission {
    background-color: white;
    &.gray {
        background-color: #f8f9fa;
    }
    padding-bottom: 0 !important;
    .sectionTitle {
        padding: 0;
    }
    
    .missionContent {
        display: flex;
        align-items: center;
        opacity: 0;
        transform: translateX(50px);
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        
        &.visible {
            opacity: 1;
            transform: translateX(0);
        }

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .missionText {
        flex: 1;
        padding: 0;

        h3 {
            font-size: 1.8rem;
            margin-top: 0;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.8;
            color: #666;
        }
    }

    .missionImage {
        flex: 1;
        min-height: 250px;
        position: relative;
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media (max-width: 768px) {
            width: 100vw;
            margin-left: -2rem;
            margin-right: -2rem;
        }
    }
}